import xw from 'xwind';
import Image from 'next/image';

import PageLayout from '@/widgets/PageLayout';
import { ButtonOutline } from '@/components/buttons';
import React from 'react';
import { useRouter } from 'next/router';

function FourOhFourPage(): JSX.Element {
  const router = useRouter();

  function handleOnClickToGoHome() {
    router.push('/');
  }

  return (
    <PageLayout>
      <div css={xw`flex-1 flex justify-center items-center flex-col`}>
        <div css={xw`mx-5 mb-16`}>
          <Image
            unoptimized
            width={405}
            height={144}
            src="/images/404/logo404.gif"
          />
        </div>

        <h1 css={xw`text-lg mb-9 text-5xl font-bold text-center`}>
          Oh no, something went wrong!
        </h1>
        <h2 css={xw`mb-16 text-xl leading-5 text-center`}>
          This page you requested could not be found.
        </h2>
        <ButtonOutline onClick={handleOnClickToGoHome}>
          Take me home
        </ButtonOutline>
      </div>
    </PageLayout>
  );
}

export default FourOhFourPage;
